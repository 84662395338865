.photo {
  display: inline-block; /* Ustawienie zdjęcia obok siebie */
  margin: 10px;
  vertical-align: top; /* Domyślne ustawienie wyrównania do góry */
}

.photo img {
  height: 200px; /* Maksymalna wysokość zdjęcia */
   /* Maksymalna szerokość zdjęcia, żeby zachować proporcje */
  min-width: 300px;
  max-width: 20em;
  /* object-fit: cover; Dopasuj zdjęcie przycinając je, aby wypełnić cały obszar */
  border: 2px solid #ccc; /* Opcjonalne obramowanie dla zdjęcia */
  border-radius: 10px; /* Opcjonalne zaokrąglenie rogów zdjęcia */
}

h2{
    font-weight: bold; /* Pogrubienie tekstu */
    color: #241407;
    text-align: center; /* Wyśrodkowanie tekstu */
    margin-bottom: 20px; /* Margines od góry i dołu */
    text-shadow: 2px 2px 4px rgba(87, 64, 64, 0.1); /* Cień dla tekstu */
  }

  
  