.zdjecia img{
    max-height: 350px; /* Maksymalna wysokość zdjęcia */
    max-width: 100%; /* Maksymalna szerokość zdjęcia, żeby zachować proporcje */
    object-fit: cover; /* Dopasuj zdjęcie przycinając je, aby wypełnić cały obszar */
    border: 2px solid #ccc; /* Opcjonalne obramowanie dla zdjęcia */
    border-radius: 10px; /* Opcjonalne zaokrąglenie rogów zdjęcia */
    margin: 5px;
}

#atrakcje ul li:hover{
    color: rgb(23, 102, 65);
  }

#atrakcje{
    margin: 20px;
}
