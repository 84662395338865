h3{
    color: rgb(12, 61, 38);
}

h2{
    color: #241407;
}

h4:hover{
    color: rgb(16, 73, 47);
}

hr{
    margin: 20px;
    border: 1px solid #4E6C50;
}

ul li:hover{
    color: rgb(23, 102, 65);
  }

i{
    font-size: small;
}